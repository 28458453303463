<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-card style="padding: 10px">
      <div style="border: 3px solid blue !important; padding: 10px">
        <v-row>
          <v-col sm="3">
            <v-autocomplete
              :items="feedbacktype_list"
              v-model="fetch_filter.feedbacktype"
              :rules="[(v) => !!v || 'required']"
              item-text="name"
              item-value="id"
              label="Feedback Type"
            ></v-autocomplete>
          </v-col>
          <v-col sm="3">
            <v-autocomplete
              :items="questiontype_list"
              v-model="fetch_filter.questiontype"
              :rules="[(v) => !!v || 'required']"
              label="QuestionType"
              item-text="tpoquestiontypename"
              item-value="tpoquestiontype_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <center>
              <v-btn
                color="indigo darken-4"
                dark
                class="mb-2"
                v-on="on"
                @click="fetch()"
                dense
              >
                Fetch</v-btn
              >
            </center>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <v-app id="inspire" class>
      <template v-if="load == true">
        <v-data-table
          hide-default-footer
          disable-pagination
          :headers="headers"
          :items="feedback_questions_list"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Feedback questions list</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <span style="width: 40px"></span>
              <v-dialog v-model="dialog" max-width="1000px">
                <template v-slot:activator="{ on }">
                  <span class="mar"
                    ><v-btn color="primary" dark class="mb-2" v-on="on">
                      <v-icon dark>mdi-plus</v-icon> Add Question</v-btn
                    ></span
                  >
                </template>
                <v-card>
                  <v-form v-model="valid">
                    <v-card-title>
                      <v-row justify="space-between">
                        <span class="headline">{{ formTitle }} </span>
                        <v-btn icon dark @click="dialog = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-autocomplete
                              :items="feedbacktype_list"
                              v-model="editedItem.feedbacktypename"
                              :rules="[(v) => !!v || 'required']"
                              label="Feedback Type*"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-autocomplete
                              :items="questiontypelistadd"
                              v-model="editedItem.tpoquestiontypename"
                              item-text="tpoquestiontypename"
                              item-value="tpoquestiontypename"
                              :rules="[(v) => !!v || 'required']"
                              label="Question Type*"
                              @change="
                                changeansfield(editedItem.tpoquestiontypename)
                              "
                            ></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              v-model="editedItem.display_no"
                              label=" display no"
                              :rules="[(v) => !!v || 'required']"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="10" md="10">
                            <v-text-field
                              v-model="editedItem.questionstatement"
                              label="Enter Question"
                              :rules="[(v) => !!v || 'required']"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="1" md="1">
                            <v-text-field
                              v-model="editedItem.weightage"
                              label="weightage"
                              :rules="[(v) => !!v || 'required']"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <div v-if="issubjectiveque == true"></div>
                        <div v-else-if="issingleoptionque == true">
                          <div
                            v-for="(item1, index) in editedItem.options_list"
                            :key="index"
                          >
                            <v-row v-if="index < 1">
                              <v-col cols="12" sm="7" md="7">
                                <v-text-field
                                  v-model="item1.optionstatement"
                                  rows="2"
                                  label="Enter option"
                                  :rules="[(v) => !!v || 'required']"
                                  @change="onChangeMcqOpttext(item1, index)"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  v-model="item1.optionweightage"
                                  label="Enter option weightage "
                                  :rules="[(v) => !!v || 'required']"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-text-field
                                  v-model="item1.optionno"
                                  label="Enter option no "
                                  :rules="[(v) => !!v || 'required']"
                                >
                                </v-text-field>
                              </v-col>
                              <div v-if="index >= 2">
                                <v-col cols="12" sm="1" md="1">
                                  <v-icon
                                    color="error"
                                    @click="removeoption(index)"
                                    >mdi-delete</v-icon
                                  >
                                </v-col>
                              </div>
                            </v-row>
                          </div>
                        </div>
                        <div v-else>
                          <v-row
                            v-for="(item1, index) in editedItem.options_list"
                            :key="index"
                          >
                            <v-col cols="12" sm="7" md="7">
                              <v-text-field
                                v-model="item1.optionstatement"
                                rows="2"
                                label="Enter option"
                                :rules="[(v) => !!v || 'required']"
                                @change="onChangeMcqOpttext(item1, index)"
                              >
                              </v-text-field
                              >
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                              <v-text-field
                                v-model="item1.optionweightage"
                                label="Enter option weightage "
                                :rules="[(v) => !!v || 'required']"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              <v-text-field
                                v-model="item1.optionno"
                                label="Enter option no "
                                :rules="[(v) => !!v || 'required']"
                              >
                              </v-text-field>
                            </v-col>
                            <div v-if="index >= 2">
                              <v-col cols="12" sm="1" md="1">
                                <v-icon
                                  color="error"
                                  @click="removeoption(index, item1.optionid)"
                                  >mdi-delete</v-icon
                                >
                              </v-col>
                            </div>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" mr="2">
                              <v-btn color="success darken-1" @click="addoption"
                                >Add option</v-btn
                              >
                              <span style="width: 20px"></span>
                            </v-col>
                          </v-row>
                        </div>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        @click="savequestion"
                        :disabled="!valid"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch
              v-model="item.isactive"
              @change="activateQuestions(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"
            ></v-switch>
          </template>

          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editQuestion(item)"
                  >edit</v-icon
                >
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deleteQuestion(item.id)">
                  mdi-delete</v-icon
                ></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-skeleton-loader
          class="ma-3 pa-2"
          v-if="true"
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </template>
    </v-app>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
  
  <script>
import axios from "axios";
import datetime from "vuejs-datetimepicker";
import Collapse from "vue-collapse";
import readXlsxFile from "read-excel-file";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: { datetime, Datepicker, Collapse },
  data: () => ({
    excelfile1: null,
    exceldialog1: false,
    url1: "",
    file: null,
    excelerrordialog1: false,
    notfoundstudent1: [],
    singleExpand: false,
    expanded: [],
    packageDisabled: false,
    valid: false,
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    dialogreschedule: false,
    issubjectiveque: false,
    issingleoptionque: false,
    table_fields_excel: {
      "Sr. No.": "srno",
      Question: "Question",
      Error: "error",
    },
    table_fields: {
      "Sr. No.": "srno",
      "Feedback Type": "feedbacktypename",
      "Question Type": "tpoquestiontypename",
      Question: "questionstatement",
      Active: "isactive",
    },

    headers: [
    {
        text: "SR No.",
        value: "srno",
      },
      {
        text: "Feedback Type",
        value: "feedbacktypename",
      },
      {
        text: "Question Type",
        value: "tpoquestiontypename",
      },
      {
        text: "Question",
        value: "questionstatement",
      },

      {
        text: "Status",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      feedbackquestion: 0,
      tpoquestiontypename: "",
      feedbacktypename: "",
      isactive: true,
      choiceaplicable: false,
      multiplechoise: false,
      questionstatement: "",
      qno: "",
      display_no: "",
      options_list: [
        {
          optionstatement: "",
          optionweightage: "",
          optionno: "1",
          optionid: "",
        },
        {
          optionstatement: "",
          optionweightage: "",
          optionno: "2",
          optionid: "",
        },
      ],
    },

    rescheduleitem: {
      company: "",
      academicyear: "",
      company_code: "",
      company_id: "",
      prev_companyoffering_id: "",
    },

    defaultItem: {
      id: 0,
      feedbackquestion: 0,
      tpoquestiontypename: "",
      feedbacktypename: "",
      isactive: true,
      choiceaplicable: false,
      multiplechoise: false,
      questionstatement: "",
      qno: "",
      display_no: "",
      options_list: [
        {
          optionstatement: "",
          optionweightage: "",
          optionno: "1",
          optionid: "",
        },
        {
          optionstatement: "",
          optionweightage: "",
          optionno: "2",
          optionid: "",
        },
      ],
    },

    menu1: false,
    mennu2: false,
    fetch_filter: {
      feedbacktype: "",
      questiontype: "",
    },
    menu: false,
    menu2: false,

    fetchaylist: [],
    feedback_questions_list: [],
    feedbacktype_list: [],
    questiontype_list: [],
    questiontypelistadd: [],
    iscurretay: "",
    data: {
      id: "",
      studofferid: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add Feedback Questions"
        : "Edit Feedback Questions";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.onLoad();
  },

  methods: {
    editQuestion(item) {
      this.iseditable = false;
      this.editedIndex = this.feedback_questions_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.feedbackquestion = item.feedbackquestion;
      this.editedItem.tpoquestiontypename = item.tpoquestiontypename;
      this.editedItem.feedbacktypename = item.feedbacktypename;
      this.editedItem.questionstatement = item.questionstatement;
      this.editedItem.qno = item.qno;
      this.editedItem.display_no = item.display_no;
      this.editedItem.options_list = item.options_list;
      this.editedItem.choiceaplicable = item.choiceaplicable;
      this.editedItem.multiplechoise = item.multiplechoise;
      this.dialog = true;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    changeansfield(questiontypename) {
      // if(obj1.choiceaplicable === "true"){
      //   this.issubjectiveque =true
      // }
      let formData = new FormData();
      formData.append("questiontypename", questiontypename);

      axios
        .post("/TPOFeedbackQuestions/getquestiontypewisefield", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.issubjectiveque = res.data.issubjectivetype;
            this.issingleoptionque = res.data.issingleoptiontype;
          }
        });
    },
    addoption() {
      if (this.editedItem.options_list.length <= 5) {
        this.editedItem.options_list.push({
          optionstatement: "",
          optionweightage: "",
          optionno: "",
          optionid: "",
        });
      }
    },

    removeoption(i, optid) {
      if (optid == "" || optid == null) {
        this.editedItem.options_list.splice(i, 1);
      } else {
        let formData = new FormData();
        formData.append("optid", optid);

        axios
          .post("/TPOFeedbackQuestions/removemcqoptionfromquestion", formData)
          .then((res) => {
            if (res.data.msg == "200") {
              this.dialog = false;
              this.showSnackbar("#4caf50", res.data.message);
              this.onLoad();
            } else {
              //console.log("fail");
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          });
      }
    },

    savequestion() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          let formData = new FormData();
          formData.append(
            "tpoquestiontypename",
            this.editedItem.tpoquestiontypename
          );
          formData.append("feedbacktypename", this.editedItem.feedbacktypename);
          formData.append(
            "questionstatement",
            this.editedItem.questionstatement
          );
          formData.append("weightage", this.editedItem.weightage);
          formData.append("editid", this.editedItem.id);

          if (this.editedItem.display_no) {
            formData.append("display_no", this.editedItem.display_no);
          }
          for (var i = 0; i < this.editedItem.options_list.length; i++) {
            formData.append(
              "optionid",
              this.editedItem.options_list[i].optionid
            );
          }
          for (var i = 0; i < this.editedItem.options_list.length; i++) {
            formData.append(
              "optionno",
              this.editedItem.options_list[i].optionno
            );
          }
          for (var i = 0; i < this.editedItem.options_list.length; i++) {
            formData.append(
              "optionstatement",
              this.editedItem.options_list[i].optionstatement
            );
          }
          for (var i = 0; i < this.editedItem.options_list.length; i++) {
            formData.append(
              "optionweightage",
              this.editedItem.options_list[i].optionweightage
            );
          }
          axios
            .post("/TPOFeedbackQuestions/saveEditFeedbackQuestions", formData)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.onLoad();
                this.showSnackbar("#4caf50", "Updated Successfully...");
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", " Already Present!!!");
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      } else {
        let formData = new FormData();
        formData.append(
          "tpoquestiontypename",
          this.editedItem.tpoquestiontypename
        );
        formData.append("feedbacktypename", this.editedItem.feedbacktypename);
        formData.append("questionstatement", this.editedItem.questionstatement);
        formData.append("weightage", this.editedItem.weightage);
        if (this.editedItem.display_no) {
          formData.append("display_no", this.editedItem.display_no);
        }

        for (var i = 0; i < this.editedItem.options_list.length; i++) {
          formData.append("optionno", this.editedItem.options_list[i].optionno);
        }
        for (var i = 0; i < this.editedItem.options_list.length; i++) {
          formData.append(
            "optionstatement",
            this.editedItem.options_list[i].optionstatement
          );
        }
        for (var i = 0; i < this.editedItem.options_list.length; i++) {
          formData.append(
            "optionweightage",
            this.editedItem.options_list[i].optionweightage
          );
        }
        axios
          .post("/TPOFeedbackQuestions/saveFeedbackQuestions", formData)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.onLoad();
              this.showSnackbar("#4caf50", "Saved Successfully...");
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", " Already Present!!!");
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
      this.close();
    },

    onLoad() {
      this.overlay = true;
      if (this.fetch_filter.feedbacktype && this.fetch_filter.questiontype) {
        this.fetch();
        this.overlay = false;
      } else {
        axios
          .post("/TPOFeedbackQuestions/showFeedbackQuestions")
          .then((res) => {
            if (res.data.msg == "200") {
              this.feedback_questions_list = res.data.feedback_questions_list;
              this.feedbacktype_list = res.data.feedbacktype_list;
              this.questiontype_list = res.data.questiontype_list;
              this.questiontypelistadd = res.data.questiontypelistadd;
              this.load = true;
            }
            this.overlay = false;
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            //window.console.log(error);
            this.overlay = false;
          });
      }
    },

    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()

    activateQuestions(item) {
      (this.data.id = item.id),
        axios
          .post("/TPOFeedbackQuestions/activateFeedbackQuestions", this.data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.onLoad();
              this.showSnackbar("green", res.data.message); // show snackbar
            }
          });
    },

    fetch() {
      this.overlay = true;
      axios
        .post("/TPOFeedbackQuestions/showFeedbackQuestions", this.fetch_filter)
        .then((res) => {
          if (res.data.msg == "200") {
            this.feedback_questions_list = res.data.feedback_questions_list;
            this.feedbacktype_list = res.data.feedbacktype_list;
            this.questiontype_list = res.data.questiontype_list;
            this.questiontypelistadd = res.data.questiontypelistadd;

            this.feedback_questions_list_size =
              res.data.feedback_questions_list_size;
            this.load = true;
          }
          this.overlay = false;
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
          this.overlay = false;
        });
    },

    deleteQuestion(item) {
      const data = {
        deleteitem: item,
      };

      axios
        .post("/TPOFeedbackQuestions/deleteFeedbackQuestions", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    }, // end of delete // end of save()
  },
};
</script>
  <style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>
  
  